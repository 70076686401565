// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import { Multiselect } from "@wizardhealth/stimulus-multiselect";

const application = Application.start();
const context = require.context(
  "controllers",
  true,
  /_controller(\.js|\.js\.erb)$/
);
application.register("multiselect", Multiselect);
application.load(definitionsFromContext(context));
