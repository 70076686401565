import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["programs", "sendEmail"];

  /**
    Updates the programs and email visibility based on the selected option.
    @param {Event} e - The event object triggered by the selection change.
  */
  updatePrograms(e) {
    this.sendEmailTarget.hidden =
      e.target.selectedOptions[0].dataset.sendMail == "false";

    if (e.target.value) {
      const url = `/program_types/${e.target.value}/form_proposal`;
      fetch(url, { headers: { Accept: "text/plain" } })
        .then((response) => response.text())
        .then((data) => {
          this.programsTarget.innerHTML = data;
        });
    }
  }
}
