import { Controller } from "@hotwired/stimulus"
  
export default class extends Controller {
  static targets = [ "programInput", "campusInput" ]

  /**
  updateCampuses()
  Fetches campuses and updates target select tag with the list of campuses fetched from the given url.
  */
  updateCampuses(){
    const url = `/programs/${this.programInputTarget.value}/campuses` 
    fetch(url , {
      method: "get",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((campuses) => {
      this.campusInputTarget.innerHTML = ""
      this.campusInputTarget.innerHTML += "<option>Sélection du campus</option>"
      campuses.forEach (campus => {
        this.campusInputTarget.innerHTML += "<option value=\"" + campus.id + "\">" + campus.name + "</option>"
      })
    })
  }
}
